import React, { FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      textDecoration: 'none',
    },
    typeLabel: {
      fontSize: 10,
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      width: 'fit-content',
      padding: '1px 3px 0',
      borderRadius: 2,
      marginBottom: 8,
      '@media screen and (min-width: 960px)': {
        fontSize: 12,
        padding: '1px 5px 0',
      },
    },
    titleText: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      color: theme.palette.primary.main,
      fontSize: '15px',
      lineHeight: '20px',
      fontWeight: 700,
      marginBottom: 8,
      wordBreak: 'break-word',
      '@media screen and (min-width: 1200px)': {
        fontSize: 14,
        lineHeight: '16.52px',
      },
    },
    authorText: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      color: theme.palette.primary.main,
      fontSize: '13px',
      lineHeight: '16px',
      '@media screen and (min-width: 1200px)': {
        fontSize: 14,
        lineHeight: '16.52px',
      },
    },
  };
});

const HackLabel: FC<any> = ({ hack }) => {
  const classes = useStyles();
  const authorString = hack.contributors.map((contributor) => contributor.name).join(', ');
  return (
    <a
      className={classes.root}
      href={hack.branchLink}
    >
      <span className={classes.titleText}>
        {hack.title}
      </span>
      <span className={classes.authorText}>
        {authorString}
      </span>
    </a>
  );
};

export default HackLabel;
