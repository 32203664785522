import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'relative',
      backgroundColor: '#FFF',
      borderRadius: '16px',
      boxShadow: '0 5px 20px rgba(0, 0, 0, 0.1)',
      padding: '32px',
      width: '100%',
      overflow: 'hidden',
      [theme.breakpoints.up(1200)]: {
        maxWidth: '372px',
      },
    },
    title: {
      fontSize: '17px',
      lineHeight: '24px',
      color: '#4F4F4F',
      fontWeight: '700',
      margin: '0 0 16px',
    },
    insightsList: {
      marginBlock: 0,
      marginInline: 0,
      paddingInline: 0,
      listStyle: 'none',
    },
    insightItem: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      fontSize: '15px',
      lineHeight: '21px',
      color: '#4F4F4F',
      marginBottom: '16px',
      '&:last-of-type': {
        marginBottom: '0',
      },
      '&:nth-child(2), &:nth-child(3)': {
        filter: 'blur(4px)'
      },
    },
    insightNumber: {
      width: '25px',
    },
    insightContent: {
      flex: 1,
    },
    mask: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'red',
    },
    button: {
      position: 'absolute',
      display: 'block',
      bottom: '0',
      left: '0',
      width: '100%',
      padding: '16px 32px',
      backgroundColor: theme.palette.secondary.main,
      color: '#FFF',
      textDecoration: 'none',
    },
    buttonLabelTop: {
      display: 'block',
      fontSize: '15px',
      lineHeight: '21px',
      fontWeight: '400',
    },
    buttonLabelBottom: {
      display: 'block',
      fontSize: '21px',
      lineHeight: '28px',
      fontWeight: '500',
    },
    buttonInner: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    buttonImage: {
      display: 'block',
      transform: 'rotate(180deg)',
      width: '44px',
    },
  };
});

export type InsightsContainerProps = {
  insights: string[];
  linkUrl: string;
}

const InsightsContainer: FC<InsightsContainerProps> = ({
  insights,
  linkUrl,
}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>
        {t('hackPage.insightsContainer.title')}
      </h3>
      <ol className={classes.insightsList}>
        {insights.map((insight, index) => {
          return (
            <li
              key={insight}
              className={classes.insightItem}
            >
              <div className={classes.insightNumber}>
                {index + 1}.
              </div>
              <span className={classes.insightContent}>
                {insight}
              </span>
              {index > 0 &&
                <div
                  className={classes.mask}
                  style={{
                    background: `linear-gradient(rgba(255, 255, 255, ${(index - 1) * 0.5}) 0%, rgba(255, 255, 255, ${index * 0.5}) 100%)`
                  }}
                />
              }
            </li>
          )
        })}
      </ol>
      <a
        className={classes.button}
        href={linkUrl}
        target='_blank'
        rel="noopener noreferrer"
      >
        <div className={classes.buttonInner}>
          <div>
            <span className={classes.buttonLabelTop}>{t('hackPage.insightsContainer.buttonTop')}</span>
            <span className={classes.buttonLabelBottom}>{t('hackPage.insightsContainer.buttonBottom')}</span>
          </div>
          <img className={classes.buttonImage} src="/img/arrow.svg" alt="" />
        </div>
      </a>
    </div>
  )
}

export default InsightsContainer;
