/* eslint no-param-reassign: "off" */
import React, { FC, useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import classnames from 'classnames';
import HackLabel from './HackLabel';
import PodcastCover from '../PodcastCover/PodcastCover';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
      overflow: 'hidden',
    },
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    carouselContainer: {
      overflow: 'hidden',
    },
    carousel: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
    },
    filterButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: '8px',
      marginBottom: '32px',
      [theme.breakpoints.up(1200)]: {
        justifyContent: 'center',
      }
    },
    filterButton: {
      backgroundColor: '#FFF',
      color: theme.palette.primary.main,
      fontSize: 13,
      lineHeight: '16px',
      fontWeight: '400',
      letterSpacing: 0,
      padding: '12px 16px',
      borderRadius: '40px',
      minWidth: 0,
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
      },
    },
    activeFilter: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
    },
    hackContainer: {
      padding: 15,
      width: '100%',
      height: '100%',
    },
    hack: {
      '@media screen and (min-width: 1200px)': {
        width: 130,
      },
    },
    hackImage: {
      position: 'relative',
      overflow: 'hidden',
      width: 130,
      height: 197,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '0 10px 10px',
      marginBottom: 14,
      filter: 'drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.1))',
    },
    hackImageButton: {
      appearance: 'none',
      background: 'none',
      width: '100%',
      height: '100%',
      padding: 0,
      border: 0,
      outline: 'none',
      '& img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        cursor: 'pointer',
      },
    },
    hackImageButtonPodcast: {
      appearance: 'none',
      background: 'none',
      width: '100%',
      height: '100%',
      padding: 0,
      border: 0,
      outline: 'none',
      cursor: 'pointer',
    },
    placeholder: {
      width: 130,
      height: 197,
      borderRadius: 10,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    overlay: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      alignItems: 'center',
      color: theme.palette.text.secondary,
      fontSize: 14,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.8)',
      '& a': {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
        '& img': {
          display: 'block',
          width: 'unset',
          height: 'unset',
        },
      },
      '& span': {
        display: 'block',
      },
    },
    linkInner: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 60,
      marginBottom: 8,
    },
    arrow: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: 197,
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      '& svg': {
        fontSize: 12,
        borderRadius: '8px 8px 0',
        color: theme.palette.primary.main,
        padding: 9.5,
        boxSizing: 'content-box',
      },
      '@media screen and (min-width: 1200px)': {
        '& svg': {
          fontSize: 26,
          padding: 13,
        },
      },
    },
    arrowLeft: {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  };
});

const HackCarousel: FC<any> = ({ hacks = [], filter = null, setFilter, hideFilters }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const [overlayOpen, setOverlayOpen] = useState(-1);
  const hackSize = 146;
  const filters = t('hackCarousel.filters').split('|');
  useEffect(() => {
    setOffset(0);
  }, [hacks]);
  return (
    <div className={classes.root}>
      {!hideFilters && (
        <Grid className={classes.filterButtons} container justifyContent="center">
          <Button
            className={classnames(classes.filterButton, !filter ? classes.activeFilter : null)}
            variant="contained"
            color="primary"
            onClick={() => {
              setFilter(null);
            }}
          >
            {filters[0]}
          </Button>
          <Button
            className={classnames(classes.filterButton, filter === 'book' ? classes.activeFilter : null)}
            variant="contained"
            color="primary"
            onClick={() => {
              setFilter('book');
            }}
          >
            {filters[1]}
          </Button>
          <Button
            className={classnames(classes.filterButton, filter === 'course' ? classes.activeFilter : null)}
            variant="contained"
            color="primary"
            onClick={() => {
              setFilter('course');
            }}
          >
            {filters[2]}
          </Button>
          <Button
            className={classnames(classes.filterButton, filter === 'documentary' ? classes.activeFilter : null)}
            variant="contained"
            color="primary"
            onClick={() => {
              setFilter('documentary');
            }}
          >
            {filters[3]}
          </Button>
          <Button
            className={classnames(classes.filterButton, filter === 'podcast' ? classes.activeFilter : null)}
            variant="contained"
            color="primary"
            onClick={() => {
              setFilter('podcast');
            }}
          >
            {filters[4]}
          </Button>
        </Grid>
      )}
      <div className={classes.container}>
        <div
          className={classnames(classes.arrow, classes.arrowLeft)}
          role="button"
          tabIndex={0}
          onClick={() => {
            if (offset > 0) {
              setOffset(offset - hackSize);
            }
          }}
          onKeyPress={e => {
            if (e.key !== 'Enter') {
              return;
            }
            if (offset > 0) {
              setOffset(offset - hackSize);
            }
          }}
        >
          <ForwardIcon />
        </div>
        <div className={classes.carouselContainer}>
          <div
            className={classes.carousel}
            style={{
              width: hacks.length * hackSize,
              transform: `translate(${-offset}px)`,
              transition: 'transform 0.5s',
            }}
          >
            {hacks.map((hack, index) => {
              return (
                <div className={classes.hack} key={hack.id}>
                  <div className={classes.hackImage}>
                    {hack.coverUrl ?
                      hack.type === 'podcast' ? (
                        <button
                          className={classes.hackImageButtonPodcast}
                          type="button"
                          onClick={() => {
                            setOverlayOpen(index);
                          }}
                        >
                          <PodcastCover
                            imageUrl={hack.coverUrl}
                            title={hack.title}
                          />
                        </button>
                      ) : (
                        <button
                          className={classes.hackImageButton}
                          type="button"
                          onClick={() => {
                            setOverlayOpen(index);
                          }}
                        >
                          <img src={hack.coverUrl} alt="Hack Cover" loading="lazy" />
                        </button>
                      ) : (
                        <div className={classes.placeholder} />
                      )}
                    {overlayOpen === index && (
                      <div className={classes.overlay}>
                        <div>
                          <a href={hack.branchLink}>
                            <div className={classes.linkInner}>
                              <span>{t('hackCarousel.view')}</span>
                              <img src="/img/view-icon.svg" alt="Eye" />
                            </div>
                          </a>
                          <div>
                            <a href={hack.affiliateLink} target="_blank" rel="noopener noreferrer">
                              <div className={classes.linkInner}>
                                <span>{t('hackCarousel.buy')}</span>
                                <img src="/img/buy-icon.svg" alt="Trolley" />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <HackLabel hack={hack} />
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={classes.arrow}
          role="button"
          tabIndex={0}
          onClick={() => {
            if (offset < (hacks.length - 1) * hackSize) {
              setOffset(offset + hackSize);
            }
          }}
          onKeyPress={e => {
            if (e.key !== 'Enter') {
              return;
            }
            if (offset < (hacks.length - 1) * hackSize) {
              setOffset(offset + hackSize);
            }
          }}
        >
          <ForwardIcon />
        </div>
      </div>
    </div>
  );
};

export default HackCarousel;
